import React from "react";
import { Input, InputGroup } from "reactstrap";
import { default as AvFieldSelect } from "components/Common/AvFieldSelect";
import { useSelector } from "react-redux";
import Loader from "components/Common/Loader";
export default function Mt5Details(props) {
  const { 
    t,
    amount,
    setAmount,
    amountValidation,
    amountError,
  } = props;

  const { accounts, loading } = useSelector((state) => state.forex.accounts);
  return (
    <div className="w-100">
      {loading ? <Loader /> : accounts && (
        <>
          <InputGroup>
            <Input
              required
              onChange={(e) => {
                setAmount(e.target.value);
              }}
              className="form-control"
              type="number"
              min="0"
              value={amount}
              placeholder="Enter 15-128341"
              onKeyPress={(e) => {
                if (!/[0-9]/.test(e.key)) {
                  e.preventDefault();
                }
              }}
            />
          </InputGroup>
          {amountValidation && (
            <p className="small text-danger "> {t("The minimum value for amount is 15")}</p>
          )}
          <div className="mt-3">
            <AvFieldSelect
              options={
                accounts?.filter(a => !["DEMO", "MAM_MASTER", "MAM_FEE", "IB"].includes(a.type))?.map(acc=>{
                  return {
                    label:`${acc.login} ${acc?.accountTypeId?.title || "-"} (${acc.Balance} ${acc.currency})`,
                    value: acc._id
                  };
                })
              }
              name="tradingAccountId"
              type="text"
              errorMessage={t("MT5 account is required")}
              validate={{ required: { value: true } }}
              label={t("MT5 Account")}
            >
            </AvFieldSelect>
          </div>
          {amountError && (
            <p className="small text-danger "> {t("Please Select Account")}</p>
          )}
        </>
      )}
    </div>
  );
}
