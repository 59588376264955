import logo from "assets/images/brands/vtindexpro.png";
import sidebarlogo from "assets/images/brands/vtindexpro-sidebar.png";
import flogo from "assets/images/brands/light-logo.png";
import lLogo from "assets/images/brands/vtindexpro.png";

export const clientName = "Vtindexpro";
export const developedBy = "Finitic";
export const imagesUrl = process.env.REACT_APP_API_IMAGES_URL;
export const cpUrl = process.env.REACT_APP_CP_URL;
export const apiUrl = process.env.REACT_APP_API_CP_DOMAIN;
export const companyName =  "Vtindexpro";
export const companyWebsite = "https://Vtindexpro.com/";
export const privacyPolicyLink = "#";
export const clientAgreementLink = "#";
export const sidebarLogo = sidebarlogo;
export const smLogo = logo;
export const mainLogo = logo;
export const lightLogo = lLogo;
export const finiticLogo = flogo;
