import CardWrapper from "components/Common/CardWrapper";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Row, Spinner, Table
} from "reactstrap";
import CustomPagination from "components/Common/CustomPagination";
import {
  Tbody, Th, Thead, Tr
} from "react-super-responsive-table";
import { getFeesDeductedAPI } from "apis/forex/mam_pam";
import moment from "moment";

const FeesTable = ({
  selectedProfile, variant = "INVESTOR"
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = React.useState(false);
  const [sizePerPage, setSizePerPage] = React.useState(5);
  const [payload, setPayload] = React.useState({
    data: [],
    pagination: {},
  });

  const fetchFeesDeducted = (page, limit) => {
    setLoading(true);
    const payload = {
      page,
      limit,
      profileId: selectedProfile._id,
    };
    if (variant === "INVESTOR") {
      payload.tradingAccountId = selectedProfile?.investorId;
    }
    console.log(payload);
    getFeesDeductedAPI({
      params: payload
    }).then((res) => {
      if (res?.isSuccess) {
        setPayload({
          data: res?.result?.docs,
          pagination: {
            ...res?.result
          }
        });
      } else throw new Error(res?.message);
    }).catch((err) => {
      console.log(err);
      setPayload({
        data: [],
        pagination: {},
      });
    }).finally(() => {
      setLoading(false);
    });
  };

  React.useEffect(() => {
    if (selectedProfile) {
      fetchFeesDeducted(1, sizePerPage);
    } else {
      console.log("No profile selected");
    }
  }, [selectedProfile, sizePerPage]);

  const getFeeName = (feeType) => {
    switch (feeType) {
      case 1: return "Daily Management Fee";
      case 2: return "Weekly Management Fee";
      case 3: return "Monthly Management Fee";
      case 4: return "Quarterly Management Fee";
      case 6: return "Yearly Management Fee";
      case 7: return "Management Fee";
      case 8: return "Per Lot Fee";
      case 9: return "Performance Fee";
    }
  };

  return (
    <CardWrapper className="mt-4 px-5 py-4 glass-card">
      <Row className="border rounded-3">
        <div
          className="table-responsive mb-0"
          data-pattern="priority-columns"
        >
          <Table
            id="tech-companies-1" className="table table-hover"
          >
            <Thead className="text-center table-light">
              <tr className="color-primary">
                <th>{t("DATE")}</th>
                <th>{t("ACCOUNT")}</th>
                <th>{t("Fee Type")}</th>
                <th>{t("AMOUNT")}</th>
              </tr>
            </Thead>
            <Tbody className="border-top">
              {loading ? (
                <tr>
                  <td className="py-4" colSpan="100%">
                    <Spinner />
                  </td>
                </tr>
              ) : payload?.data?.length === 0 ? (
                <tr>
                  <td colSpan={4} className="w-100 text-center text-bold">
                    {t("No Fees Collected")}
                  </td>
                </tr>
              ) : (
                payload?.data?.map((fee, index) => (
                  <tr
                    key={index}
                    className="border-top"
                  >
                    <td className="centerV">
                      {moment(fee?.createdAt).format("DD MMM YYYY  HH:mm:ss")}
                    </td>
                    <td className="centerV">
                      {fee?.investorAccountId?.login}
                    </td>
                    <td className="centerV">
                      {getFeeName(fee?.feeType)}
                    </td>
                    <td className="centerV">
                      {fee?.amount.toFixed(2)}
                    </td>
                  </tr>
                ))
              )}
            </Tbody>
          </Table>
        </div>
      </Row>
      <div className="mt-4">
        <CustomPagination
          {...payload.pagination}
          setSizePerPage={setSizePerPage}
          sizePerPage={sizePerPage}
          onChange={fetchFeesDeducted}
        />
      </div>
    </CardWrapper>
  );
};

export default FeesTable;
