module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  SOCKET_URL: process.env.REACT_APP_API_CP_DOMAIN,
  BACKEND_CP_URL: process.env.REACT_APP_SOCKET_URL,
  MAX_UNAPPROVED_ACCOUNTS: 3,
  enableFX: process.env.REACT_APP_ENABLE_FX === "true" || true,
  enableCrypto: process.env.REACT_APP_ENABLE_CRYPTO === "true" || false,
  enableCryptoWallets: process.env.REACT_APP_ENABLE_CRYPTO_WALLETS === "true" || false,
  defaultPortal: process.env.REACT_APP_DEFAULT_PORTAL || "FOREX",
  companyCryptoDeposit: process.env.REACT_APP_COMPANY_CRYPTO_DEPOSIT === "true" || false,
  COMPANY_WALLETS: [
    {
      name:"USDT",
      networks: [
        {
          name: "TRC20",
          address: "TN3FrE84GjbNemcAXb2W4W6Nua95PhgrRq",
          tag: "TRC20",
        }
      ],
    }
  ],
  ENABLE_DARK_MODE: true,
  ENABLE_GLOBAL_SEARCH: false,
  LEVERAGES: [1, 50, 100],
  PLATFORMS: [
    {
      name: "Desktop Trader - MT5",
      logo: "https://i0.wp.com/www.marketcalls.in/wp-content/uploads/2012/01/metatrader-logo.png",
      image: "https://www.metatrader5.com/i/main/metatrader-5-windows.jpg",
      isLocalImg: false,
      downloadLink: "https://download.mql5.com/cdn/web/vtindex.llc/mt5/vtindex5setup.exe",
    },
    {
      name: "Android Trader - MT5",
      logo: "https://i0.wp.com/www.marketcalls.in/wp-content/uploads/2012/01/metatrader-logo.png",
      image: "https://i.ytimg.com/vi/MTLbJ5lSW9M/maxresdefault.jpg",
      isLocalImg: false,
      downloadLink: "https://play.google.com/store/apps/details?id=net.metaquotes.metatrader5&hl=en&referrer=ref_id%3D5189013753079956506%26server%3DVTindex-MT5&pli=1",
    },
    {
      name: "IOS Trader - MT5",
      logo: "https://i0.wp.com/www.marketcalls.in/wp-content/uploads/2012/01/metatrader-logo.png",
      image: "https://www.ifcmarkets.ae/images/front/platforms/MT4-MT5-mobile-ios.png",
      isLocalImg: false,
      downloadLink: "https://apps.apple.com/us/app/metatrader-5-forex-stocks/id413251709",
      
    },
  ],
};
